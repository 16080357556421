exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-affiliate-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/currency/affiliate.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-affiliate-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-currency-system-design-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/currency/currency-system-design.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-currency-system-design-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-expected-distribution-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/currency/expected-distribution.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-expected-distribution-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-getting-started-copy-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/currency/getting-started copy.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-getting-started-copy-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-issuance-modeling-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/currency/issuance-modeling.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-issuance-modeling-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-price-calculations-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/currency/price-calculations.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-price-calculations-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-redemptions-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/currency/redemptions.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-redemptions-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-storyfuel-supply-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/currency/storyfuel-supply.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-storyfuel-supply-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-tokenomics-design-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/currency/tokenomics-design.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-currency-tokenomics-design-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-hive-economy-hive-economy-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/hive-economy/hive-economy.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-hive-economy-hive-economy-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-hive-network-footnotes-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/hive-network/footnotes.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-hive-network-footnotes-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-hive-network-hive-network-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/hive-network/hive-network.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-hive-network-hive-network-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-creator-growth-rates-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/introductory/creator-growth-rates.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-creator-growth-rates-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-current-landscape-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/introductory/current-landscape.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-current-landscape-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-faq-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/introductory/faq.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-faq-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-getting-started-copy-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/introductory/getting-started copy.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-getting-started-copy-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-humm-uses-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/introductory/humm-uses.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-humm-uses-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-introducing-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/introductory/introducing.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-introducing-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-problems-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/introductory/problems.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-problems-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-projections-creator-economy-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/introductory/projections-creator-economy.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-projections-creator-economy-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-projections-humm-creator-economy-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/introductory/projections-humm-creator-economy.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-projections-humm-creator-economy-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-stakeholders-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/introductory/stakeholders.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-introductory-stakeholders-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-reserve-currencies-accepted-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/reserve/currencies-accepted.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-reserve-currencies-accepted-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-reserve-issuance-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/reserve/issuance.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-reserve-issuance-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-reserve-per-hive-system-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/reserve/per-hive-system.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-reserve-per-hive-system-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-reserve-redemption-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/reserve/redemption.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-reserve-redemption-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-reserve-reserve-mang-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/reserve/reserve-mang.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-reserve-reserve-mang-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-reserve-transparency-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/docs/reserve/transparency.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-reserve-transparency-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js-content-file-path-src-home-index-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/homepage-query.js?__contentFilePath=/Users/pedroroman/Documents/GitHub/whiteppaer/rocketdocs/src/home/index.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js-content-file-path-src-home-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

